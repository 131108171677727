import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SiteMenu from "../site-menu/SiteMenu";
import Logo from "../Logo";
import MenuIcon from "../icons/Menu";
import useScroll from "../../assets/hooks/useScroll";
import "./navigation.scss";
import { Dictionary, withLanguage } from "../../assets/js/contexts";
import { useLang } from "../../helpers/language";
import history from "../../helpers/history";

const Navigation = ({ dictionary }) => {
  // eslint-disable-next-line
  const [dark, setDark] = useState(false);
  const [showMenu, toggleMenu] = useState(false);
  const scroll = useScroll();
  const route = useLocation().pathname;
  const { lang, setlang } = useLang();
  const dataRoute = route.split("/")[1];

  const changeLanguage = (e) => {
    if (e.target.lang === "ru") {
      history.push(`${route}?lang=ru`);
      setlang("ru");
      document.documentElement.lang = "ru";
    } else if (e.target.lang === "hy") {
      history.push(`${route}?lang=hy`);
      setlang("hy");
      document.documentElement.lang = "am";
    } else if (e.target.lang === "en") {
      history.push(`${route}?lang=en`);
      setlang("en");
      document.documentElement.lang = "en";
    }
  };

  return (
    <Dictionary.Provider value={dictionary}>
      <div>
        <nav data-dark={dark}
          data-route={
            `/${dataRoute}`
          }
          data-scrolled={
            scroll > 200
          }>
          <div className="container-fluid p-0">
            <div className="row justify-content-between align-items-center no-gutters">
              <button onClick={
                () => toggleMenu(true)
              } aria-label="toggle menu">
                <MenuIcon fill={
                  dark ? "black" : "white"
                } />
              </button>
              <Link to="/">
                <Logo fill={
                  dark ? "black" : "white"
                }
                  small={
                    scroll > 200
                  } />
              </Link>
              <div className="switcher">
                <p onClick={changeLanguage}
                  lang="en"
                  data-selected={
                    lang === "en"
                  }>
                  {/* <a data-selected={lang === "en"}> */}
                  {
                    dictionary["English"][lang]
                  }
                  {/* </a> */} </p>
                {/* <hr /> */}
                <p onClick={changeLanguage}
                  lang="ru"
                  data-selected={
                    lang === "ru"
                  }>
                  {/* <a data-selected={lang === "hy"}> */}
                  {
                    dictionary["Russian"][lang]
                  }
                  {/* </a> */} </p>
                {/* <hr /> */}
                <p onClick={changeLanguage}
                  lang="hy"
                  data-selected={
                    lang === "hy"
                  }>
                  {/* <a data-selected={lang === "hy"}> */}
                  {
                    dictionary["Armenian"][lang]
                  }
                  {/* </a> */} </p>
              </div>
            </div>
          </div>
        </nav>

        {
          showMenu && <SiteMenu toggleMenu={toggleMenu} />
        } </div>
    </Dictionary.Provider>
  );
};

export default withLanguage(Navigation);
