import { useState, useEffect } from 'react'

export default function useScroll () {
    const [scroll, setScroll] = useState(0)

    const onScroll = () => {
        setScroll(window.scrollY)
    }

    useEffect(() => {
        onScroll()
        window.addEventListener('scroll', onScroll)

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    })

    return scroll
}