import { createContext } from 'react'

export const Dictionary = createContext({})
export const Language = createContext('en')

export const withLanguage = Component => ({...props}) => (
    <Language.Consumer>
        {lang => (
            <Component
                {...props}
                lang={lang}/>
        )}
    </Language.Consumer>
)

export const withDictionary = Component => ({...props}) => (
    <Dictionary.Consumer>
        {dictionary => (
            <Component
                {...props}
                dictionary={dictionary}/>
        )}
    </Dictionary.Consumer>
)