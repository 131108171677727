import { useState, useEffect } from "react";
import AnimatedNumber from 'react-animated-number';
// import AnimatedNumber from "animated-number-react";
import LegalAgeModal from "./LegalAgeModal";
import useWindowSize from "../../assets/hooks/useWindowSize";
import Logo from "../Logo";
import "./loader.scss";
import { withLanguage } from "../../assets/js/contexts";

const Loader = ({ setAdult, lang }) => {
  const speed = 20;
  const { width } = useWindowSize();
  const [progress, setProgress] = useState(0);
  const [circle, setCircle] = useState({
    diff: 0,
    radius: 0,
    offset: 0,
    circumference: 0,
    width: 0,
    transitionSpeed: 0,
  });
  useEffect(() => {
    document.querySelector("body").classList.add("no-scroll");

    return () => {
      document.querySelector("body").classList.remove("no-scroll");
    };
  }, []);

  useEffect(() => {
    const diff = width > 576 ? 0.4 : 0.82;
    const w = (width * diff) / 2;
    const radius = w > 0 ? w - 2 : 0;
    const circumference = radius * 2 * Math.PI;
    const offset = circumference - (progress / 100) * circumference;

    setCircle({
      diff,
      width: w,
      radius,
      circumference,
      offset,
      transitionSpeed: progress > 20 ? 0.35 : 0.05,
    });
  }, [width, setCircle, progress]);

  useEffect(() => {
    let count = 0;
    let interval = setInterval(() => {
      setProgress(count + 1);
      count++;

      if (count === 40) {
        count = 70;
      }

      if (count === 100) {
        clearInterval(interval);
        setAdult(
          localStorage.adult
            ? localStorage.adult === "true"
              ? true
              : false
            : undefined
        );
      }
    }, speed);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [setProgress]);

  return (
    <div id="loaderd">
      <Logo fill="white" />
      <svg
        height={width * circle.diff}
        width={width * circle.diff}
        className="loaderd"
      >
        <circle
          stroke="#676767"
          strokeWidth="1"
          fill="transparent"
          cx={circle.width}
          cy={circle.width}
          r={circle.radius}
        />
        <circle
          stroke="#FFBE03"
          strokeWidth="1"
          fill="transparent"
          style={{ transition: `stroke-dashoffset ${circle.transitionSpeed}s` }}
          strokeDashoffset={circle.offset}
          strokeDasharray={`${circle.circumference} ${circle.circumference}`}
          cx={circle.width}
          cy={circle.width}
          r={circle.radius}
        />
      </svg>
      <AnimatedNumber
        value={progress}
        duration={500}
        stepPrecision={0}
        className="progress bg-transparent"
        formatValue={(n) => `${n}%`}
      />

      {progress === 100 && <LegalAgeModal setAdult={setAdult} lang={lang} />}
    </div>
  );
};

export default withLanguage(Loader);
