import React, { useState, Suspense, useEffect } from 'react';
// Helpers
import history from '../helpers/history.jsx';
import Loader from '../components/loader/Loader';
import { useStateWithLocalStorage } from '../helpers/storage';
import { Language } from '../helpers/language';
import * as API from '../helpers/api';
import axios from 'axios';
import { cookies, setCookies } from '../helpers/cookies';
import config from '../config';
import { ModalProvider } from '../helpers/modal_provider';

import Footer from '../components/footer/Footer';
import Navigation from '../components/navigation/Navigation';
import dictionary from '../assets/js/dictionary';
import Modal from '../helpers/modal';

// eslint-disable-next-line
import Loading from 'react-loader-spinner';
//Routing
import { Router, Route, Switch, Redirect } from 'react-router-dom';

// Components  React.lazy(()=> import("./components/specials/special"));
const Home = React.lazy(() => import('./home'));
const Assets = React.lazy(() => import('./media-assets'));
const AboutUs = React.lazy(() => import('./about-us'));
const Blog = React.lazy(() => import('./blog'));
const BlogPost = React.lazy(() => import('./blog-post'));
const ContactUs = React.lazy(() => import('./contact-us'));
const Brewery = React.lazy(() => import('./brewery'));
const Brewpub = React.lazy(() => import('./brewpub'));
const Beer = React.lazy(() => import('./craft-beer'));
const Special = React.lazy(() => import('./special'));
const Cider = React.lazy(() => import('./cider'));
const Partnership = React.lazy(() => import('./partnership'));
const Page404 = React.lazy(() => import('./page404'));
const PrivacyPolicy = React.lazy(() => import('./privacyPolicy'));
const TermsOfService = React.lazy(() => import('./termsOfService'));
function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
function RouterComponent() {
  window.scrollTo(0, 0);
  return (
    <Switch>
      <Route exact path={'/'}>
        <Redirect to='/home' />
      </Route>
      <Route exact path='/home' component={Home} />
      <Route exact path='/assets' component={Assets} />
      <Route exact path='/about-us' component={AboutUs} />
      <Route exact path='/blog-post/:id' component={BlogPost} />
      {/*<Route exact path="/beer" component={Beer} />*/}
      <Route exact path='/brewery' component={Brewery} />
      <Route exact path='/brewpub' component={Brewpub} />
      <Route exact path='/contact-us' component={ContactUs} />
      <Route exact path='/cider' component={Cider} />
      <Route exact path='/beer' component={Beer} />
      <Route exact path='/beer/:id' component={Beer} />
      <Route exact path='/partnership' component={Partnership} />
      <Route exact path='/special' component={Special} />
      <Route exact path='/blog' component={Blog} />
      <Route exact path={config.privacyPolicy.link} component={PrivacyPolicy} />
      <Route
        exact
        path={config.termsOfService.link}
        component={TermsOfService}
      />
      <Route path='*' component={Page404} />
      <ScrollToTop />
    </Switch>
  );
}

function App() {
  let [lang, setlang] = useStateWithLocalStorage('language');
  let [loggedIn, setLoggedIn] = useState(false);
  let url = new URL(window.location.href);
  let restParams = {
    lang: url.searchParams.get('lang'),
  };
  let [open, setopen] = useState(false);
  // eslint-disable-next-line
  const [route, setRoute] = useState('');
  const [adult, setAdult] = useState(undefined);

  async function login(keyfile) {
    if (keyfile) {
      API.post(config.authentification.login, { keyfile })
        .then(async ({ token }) => {
          API.settings.headers.keyfile = token;
          setCookies(token);
          setLoggedIn(true);
        })
        .catch((error) => {
          console.error(error);
          setLoggedIn(false);
        });
    } else {
      setLoggedIn(false);
      console.log(
        '%c There is no such a keyfile! ',
        'background: #222; color: #04b4e0'
      );
    }
  }

  async function checkToken() {
    let token = cookies.get(config.authentification.tokenAddress);
    if (token) {
      API.post(config.authentification.verify, {
        token,
      })
        .then(async (res) => {
          if (res.status === 'success' || res.status === 200) {
            setCookies(token);
            setLoggedIn(true);
          }
        })
        .catch((res) => {
          setLoggedIn(false);
          console.error(res);
          console.error(`Please don't edit cookies manually`);
          cookies.remove('authorization');
        });
    } else {
      setLoggedIn(false);
      console.log('fires login from cehckookies');
      login(config.authentification.key);
    }
  }
  const modalToggle = () => {
    if (!open) document.body.classList.add('show-modal');
    else document.body.classList.remove('show-modal');

    setopen(!open);
  };
  useEffect(() => {
    if (adult === false) {
      alert('You have to be 18 or over to enter this site');
      // window.location = "https://www.facebook.com/DargettCraftBeer/";
    }
  }, [adult]);

  useEffect(() => {
    (async () => {
      await checkToken();

      const routes = [
        { path: '/contact-us' },
        { path: '/home' },
        { path: '/about-us' },
        { path: '/brewery' },
        { path: '/brewpub' },
        { path: '/beer' },
        { path: '/special' },
        { path: '/cider' },
        { path: '/blog' },
        { path: '/partnership' },
      ];

      if (
        restParams.lang &&
        (restParams.lang === 'en' ||
          restParams.lang === 'hy' ||
          restParams.lang === 'ru')
      ) {
        setlang(restParams.lang);
      } else {
        setTimeout(() => {
          axios
            .get('https://ipapi.co/json/')
            .then(({ data }) => {
              document.getElementById('meta-locale').content = data.country;
              if (lang !== 'en' && lang !== 'ru' && lang !== 'hy') {
                if (data.country === 'en') {
                  setlang('en');
                  localStorage.country = 'en';
                } else if (data.country === 'ru') {
                  setlang('ru');
                  localStorage.country = 'ru';
                } else {
                  console.log(8);
                  setlang('hy');
                  localStorage.country = 'hy';
                }
              }
            })
            .catch((error) => {
              if (lang !== 'en' && lang !== 'ru' && lang !== 'hy') {
                setlang('hy');
                localStorage.country = 'hy';
              }
            });
        }, 0);
        if (lang !== 'en' && lang !== 'ru' && lang !== 'hy') {
          if (localStorage.getItem('country') === 'en') {
            setlang('en');
          } else if (localStorage.getItem('country') === 'ru') {
            setlang('ru');
          } else {
            setlang('hy');
          }
        }
      }
      await import('../components/loader/loader.scss');
      await import('../assets/css/pages/index.scss');
      await import('../assets/css/app.scss');
    })();

    // eslint-disable-next-line
  }, [loggedIn]);

  // useEffect(() => {
  //   setTimeout(async () => {
  //     await import('../assets/css/modal.sass');
  //     modalToggle();
  //   }, 5000);
  // }, []);

  if (loggedIn === null || loggedIn === false)
    return <Loading type='Oval' color='#FFBE03' className='loader' />;
  else if (loggedIn === true)
    return (
      <Language.Provider value={{ lang, setlang }}>
        <ModalProvider.Provider
          value={{
            open,
            setopen,
            modalToggle,
          }}>
          <div className='body' data-lang={lang} data-route={route}>
            <Modal />
            <Router history={history}>
              {!adult ? (
                // <>loader</>
                <Loader adult={adult} setAdult={setAdult} />
              ) : (
                <>
                  {/* <Header /> */}
                  <Navigation dictionary={dictionary} />
                  <Switch>
                    <Suspense
                      fallback={
                        <Loading
                          type='Oval'
                          color='#FFBE03'
                          className='loader'
                        />
                      }>
                      <RouterComponent />
                      <Footer dictionary={dictionary} />
                    </Suspense>
                  </Switch>
                </>
              )}
            </Router>
          </div>
        </ModalProvider.Provider>
      </Language.Provider>
    );
}

export default App;
