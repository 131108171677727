import Cookies from 'universal-cookie';
import config from '../config';

export const cookies = new Cookies();
export let token = cookies.get(config.authentification.tokenAddress);
export const setCookies = (data) => {
    token = cookies.get(config.authentification.tokenAddress);
    let date = new Date();
    date.setTime(date.getTime() + date.minutes * 60 * 1000);
    cookies.set(config.authentification.tokenAddress, data, {
        path: '/',
        expires: date,
    });
};