const config = {
  api: {
    homepage: "homepage",
    aboutus: "aboutus",
    aboutusIndependent: "aboutus-independent",
    aboutusCraft: "aboutus-craft",
    aboutusSmall: "aboutus-small",
    blogTitle: "blog-title",
    blog: "blog",
    blogPost: "blog-post",
    news: "news",
    newsPost: "news-post",
    brewery: "brewery",
    breweryIngridient: "brewery-ingridient",
    breweryProcess: "brewery-process",
    brewpub: "brewpub",
    beer: "beer",
    beerPage: "beer-page",
    cup: "cup",
    cupPage: "cup-page",
    special: "special",
    contactus: "contactus",
    contactBrewery: "contact-brewery",
    contactBrewpub: "contact-brewpub",
    cider: "cider",
    category: "category",
    contactTitle: "contact-title",
    contactPart: "contact-part",
    contactForm: "contact-form",
    contactType: "contact-type",
    currency: "currency",
    emailjs: "emailjs",
    email: "email",
    intro: "intro",
    lang: "lang",
    order: "order",
    orderTitle: "order-title",
    privilegeTitle: "privilege-title",
    privilege: "privilege",
    portfolioTitle: "portfolio-title",
    portfolio: "portfolio",
    productTitle: "product-title",
    product: "product",
    partnership: "partnership",
    partner: "partner",

    position: "position",
    privacyPolicy: "privacy-policy",
    routing: "routing",
    serviceTitle: "service-title",
    termsOfService: "terms-of-service",
    service: "service",
    serviceType: "service-type",
    socialLink: "social-link",
    slider: "slider",
    specialTitle: "special-title",
    tag: "tag",
    teamTitle: "team-title",
    team: "team",
    theme: "theme",
    map: "map",
    mapType: "map-type",
    webcardIntro: "webcard-intro",
    webcardAbout: "webcard-about",
    webcardPrivilegeTitle: "webcard-privilege-title",
    webcardPrivilege: "webcard-privilege",
    webcardUsecasesTitle: "webcard-usecases-title",
    webcardUsecases: "webcard-usecases",
    webcardExclusiveTitle: "webcard-exclusive-title",
    webcardExclusive: "webcard-exclusive",
    webcardHowtobuyTitle: "webcard-howtobuy-title",
    webcardHowtobuy: "webcard-howtobuy",
    uploads: "uploads",
    API_URL: process.env.REACT_APP_API_URL,
    MAINAPP_URL: process.env.REACT_APP_MAINAPP_URL,
    API_Version: "v1",
  },
  pages: {
    home: {
      homeTitle: {
        white: {
          en: "First Armenian",
          ru: "Первое Армянское",
          hy: "Առաջին Հայկական",
        },
        yellow: {
          en: "Craft Beer",
          ru: "Крафтовое Пиво",
          hy: "Քրաֆթ Գարեջուրը",
        },
      },
      aboutCraft: {
        title: {
          en: "Craft beer",
          ru: "Крафтовое пиво",
          hy: "Քրաֆթ գարեջուր",
        },
        desc: {
          en: "Dargett remains a small independent craft brewery, staying true to the craft ideology. We only use the highest quality ingredients that we can source from all over the world, all our beers are unpasteurized, unfiltered, and brewed in traditional methods to achieve a perfect serve.",
          ru: "Dargett- небольшая независимая ремесленная пивоварня, остающаяся верной идеологии крафтового пива. Мы используем только самые качественные ингредиенты со всего мира. Наше пиво непастеризованное, не фильтровано и сварено традиционными методами",
          hy: "Դառգետը շարունակում է լինել փոքր քրաֆթ գարեջրատուն, որը հավատարիմ է մնում քրաֆթի գաղափարախոսությանը: Գարեջուրը պատրաստվում է գարեջրագործության մեջ կիրառվող արտադրական վերջին տեխնոլոգիաներին համապատասխան՝ օգտագործելով միայն բարձր որակ ունեցող բաղադրիչներ, որոնք մեզ են հասնում ամբողջ աշխարհից։ Դառգետ գարեջրի որևէ տեսակ պաստերիզացված և ֆիլտրված չէ, այն պատրաստվում է ավանդական մեթոդով՝ հասնելով կատարելության։",
        },
      },
      locationInfo: {
        title: {
          en: "Find your Dargett in the City",
          ru: "Найди свой Dargett в городе",
          hy: "Գտիր քո Դառգետը քաղաքում",
        },
        desc: {
          en: "Our mission is to bring Craft beer culture closer to Armenian people, check out our progress. ",
          ru: "Наша миссия - приблизить культуру крафтового пива к армянскому народу, оцените наши успехи.",
          hy: "Մեր առաքելությունն է ավելի մոտեցնել Craft գարեջրի մշակույթը հայ ժողովրդին, ստուգել մեր առաջընթացը:",
        },
      },
    },
    brewpub: {
      cupsTitle: {
        en: "Dargett Brew-Pub serves",
        ru: "В Dargett Brewpub подают",
        hy: "Dargett Brewpub-ը մատուցում է",
      },
      ourMenu: {
        title: {
          en: "Our Menu",
          ru: "Наше меню",
          hy: "Մեր մենյուն",
        },
        desc: {
          en: "Dargett Brewpub has a wide selection of delicious food.",
          ru: "Dargett Brewpub предлагает широкий выбор вкусных блюд.",
          hy: "Դառգետ գարեջրատունը ունի հարուստ ճաշացանկ՝ յուրահատուկ ուտեստներով կոճակը՝ դիտել",
        },
        button: {
          en: "See more",
          ru: "Открыть",
          hy: "Դիտել",
        },
      },
    },
  },
  aboutUsBlock: {
    home: {
      title: {
        en: "About Us",
        ru: "О нас",
        hy: "Մեր մասին",
      },
      subTitle: {
        en: "About Us",
        ru: "О нас",
        hy: "Մեր մասին",
      },
      content: {
        en: "About Us",
        ru: "О нас",
        hy: "Մեր մասին",
      },
      button: {
        en: "More",
        ru: "Подробнее",
        hy: "Ավելին",
      },
    },
    aboutUs: {
      title: {
        en: "Dargett Brewery",
        ru: "Пивоварня Dargett",
        hy: "Դառգետ գարեջրի գործարան",
      },
      subTitle: {
        en: "Dargett Brewery",
        ru: "Пивоварня Dargett",
        hy: "Դառգետ գարեջրի գործարան",
      },
      content: {
        en: "Dargett Brewery",
        ru: "Пивоварня Dargett",
        hy: "Դառգետ գարեջրի գործարան",
      },
      button: {
        en: "More",
        ru: "Подробнее",
        hy: "Ավելին",
      },
    },
    brewery: {
      title: {
        en: "Dargett Brewpub",
        ru: "Dargett Брюпаб",
        hy: "Դառգետ գարեջրատուն",
      },
      subTitle: {
        en: "Dargett Brewpub",
        ru: "Dargett Брюпаб",
        hy: "Դառգետ գարեջրատուն",
      },
      content: {
        en: "Dargett Brewpub",
        ru: "Dargett Брюпаб",
        hy: "Դառգետ գարեջրատուն",
      },
      button: {
        en: "More",
        ru: "Подробнее",
        hy: "Ավելին",
      },
    },
    brewpub: {
      title: {
        en: "Dargett  Bottled Beer",
        ru: "Бутылочное пиво Dargett",
        hy: "Դառգետ շշալցված գարեջուր",
      },
      subTitle: {
        en: "Dargett  Bottled Beer",
        ru: "Бутылочное пиво Dargett",
        hy: "Դառգետ շշալցված գարեջուր",
      },
      content: {
        en: "Dargett  Bottled Beer",
        ru: "Бутылочное пиво Dargett",
        hy: "Դառգետ շշալցված գարեջուր",
      },
      button: {
        en: "More",
        ru: "Подробнее",
        hy: "Ավելին",
      },
    },
    contactUs: {
      title: {
        en: "Contacts",
        ru: "Контакты",
        hy: "Կապ մեզ հետ",
      },
      subTitle: {
        en: "Dargett  Bottled Beer",
        ru: "Бутылочное пиво Dargett",
        hy: "Դառգետ շշալցված գարեջուր",
      },
      content: {
        en: "Dargett  Bottled Beer",
        ru: "Бутылочное пиво Dargett",
        hy: "Դառգետ շշալցված գարեջուր",
      },
      button: {
        en: "More",
        ru: "Подробнее",
        hy: "Ավելին",
      },
    },
  },
  buttons: {
    brewpub: {
      cupDetials: {
        en: "Detials",
        ru: "Подробно",
        hy: "Մանրամասն",
      },
    },
  },
  authentification: {
    login: "/login/keyfile",
    verify: "/verify",
    tokenAddress: "keyfile",
    key: process.env.REACT_APP_API_KEY,
  },
  copyright: {
    en: "All Rights Reserved | © Dargett 2021",
    ru: "Авторские права защищены | © Dargett 2021",
    hy: "Հեղինակային իրավունքները պաշտպանված են | © Dargett 2021",
  },
  brand: {
    name: "Dargett.com",
    link: "https://dargett.com",
    logo: "/Favicon.png",
  },
  privacyPolicy: {
    name: {
      en: "Privacy Policy",
      ru: "Политика конфиденциальности",
      hy: "Գաղտնիության քաղաքականություն",
    },
    link: "/privacy-policy",
  },
  termsOfService: {
    name: {
      en: "Terms of Service",
      ru: "Условия использования",
      hy: "Ծառայությունների մատուցման պայմաններ",
    },
    link: "/terms-of-service",
  },
  swal: {
    success: {
      en: {
        icon: "success",
        title: "Great",
        text: "Your message was sent",
      },
      ru: {
        icon: "success",
        title: "Отлично",
        text: "Ваше сообщение было отправлено",
      },
      hy: {
        icon: "success",
        title: "Շատ լավ",
        text: "Ձեր հաղորդագրությունն ուղարկվել է",
      },
    },
    websiteForm: {
      en: {
        icon: "success",
        title: "Great",
        text: "Your request was sent, we sent back the information for further steps, please check your Email address.",
      },
      ru: {
        icon: "success",
        title: "Отлично",
        text: "Ваш запрос был отправлен, мы отправили информацию для дальнейших действий, проверьте свой адрес электронной почты.",
      },
      hy: {
        icon: "success",
        title: "Շատ լավ",
        text: "Ձեր հարցումն ուղարկվել է, մենք հետ ենք ուղարկել տեղեկատվությունը հետագա քայլերի համար, խնդրում ենք ստուգել ձեր էլ. Փոստի հասցեն:",
      },
    },
    websiteFormError: (args) => {
      return {
        en: {
          icon: "error",
          title: "Oops",
          text: `Error code #101, You entered the following fields incorrectly: ${args?.map(
            (arg) => arg.param
          )}, try again․ If the problem is not clear please contact support by websites form or sending message to support@dargett.com email address`,
        },
        ru: {
          icon: "error",
          title: "Ой",
          text: `Код ошибки #101, Вы неправильно ввели следующие поля: ${args?.map(
            (arg) => arg.param
          )}, попробуйте еще раз. В случае, если проблема не ясна обратитесь в службу поддержки через форму на веб-сайте или отправив сообщение на адрес электронной почты support@dargett.com`,
        },
        hy: {
          icon: "error",
          title: "Վայ",
          text: `Սխալմունք #101, սխալ եք մուտքագրել հետևյալ դաշտերը՝ ${args?.map(
            (arg) => arg.param
          )}, կրկին փորձեք: Ծագած խնդրի ոչ պարզ լինելու պարագայում դիմեք սպասարկման կենտրոն օգտագործելով կայքի հետադաչձ կապի միջոցը կամ ուղղարկեք էլ նամակ support@dargett.com հասցեին`,
        },
      };
    },
    error: {
      en: {
        icon: "error",
        title: "Oops",
        text: "You have to fill all fields!",
      },
      ru: {
        icon: "error",
        title: "Ой",
        text: "Вы должны заполнить все поля!",
      },
      hy: {
        icon: "error",
        title: "Վայ",
        text: "Դուք պետք է լրացնեք բոլոր դաշտերը:",
      },
    },
    usernameIsBusy: {
      en: {
        icon: "error",
        timer: 3000,
        showConfirmButton: false,
        title: "Oops",
        text: "The username already taken, choose another one",
      },
      ru: {
        icon: "error",
        timer: 3000,
        showConfirmButton: false,
        title: "Ой",
        text: "Имя пользователя уже занято, выберите другое!",
      },
      hy: {
        icon: "error",
        timer: 3000,
        showConfirmButton: false,
        title: "Վայ",
        text: "Օգտագործողի անունն արդեն զբաղված է, ընտրեք մեկ ուրիշը:",
      },
    },
    emailIsBusy: {
      en: {
        icon: "error",
        timer: 3000,
        showConfirmButton: false,
        title: "Oops",
        text: "The email already taken, choose another one",
      },
      ru: {
        icon: "error",
        timer: 3000,
        showConfirmButton: false,
        title: "Ой",
        text: "Мейл уже занято, выберите другое!",
      },
      hy: {
        icon: "error",
        timer: 3000,
        showConfirmButton: false,
        title: "Վայ",
        text: "Էլ հասցեն արդեն զբաղված է, ընտրեք մեկ ուրիշը:",
      },
    },
  },
};

export default config;
