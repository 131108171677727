import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import React, { useEffect, useState } from 'react';
import * as API from '../../helpers/api';
import { Link } from 'react-router-dom';
import './beer-slider.scss';
import config from '../../config';
import { useLang } from '../../helpers/language';

SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);
const BeerSlider = ({ toggleMenu }) => {
  const { lang } = useLang();
  const [isBusy, setBusy] = useState(true);
  const [bottles, setBottles] = useState([]);
  useEffect(() => {
    (async () => {
      let rowBottles = await API.get(config.api.beer, {}, {}, true);
      setBottles(rowBottles);

      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  return (
    <div id='beer-slider'>
      <button
        className={'swiper-button-prev d-none d-md-block'}
        aria-label='prev'
      >
        <img src='/static/icons/arrow-white.svg' data-arrow='left' alt='' />
      </button>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation={false}
        breakpoints={{
          640: {
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          },
        }}
        pagination={true}
        // pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
      >
        {bottles.map((bottle, index) => (
          <SwiperSlide key={index}>
            <Link
              to={`/beer/${bottle._id}`}
              key={index}
              onClick={() => {
                toggleMenu(false);
              }}
            >
              <div
                key={index}
                className='d-flex justify-content-center flex-wrap'
              >
                <img
                  src={
                    bottle?.uploads
                      ? `${config.api.API_URL}/${
                          bottle?.uploads && bottle?.uploads[0]?.path
                        }`
                      : null
                  }
                  alt=''
                  height='500px'
                />
                <h6 className='w-100'>
                  {bottle?.name ? bottle.name[lang] : ''}
                </h6>
                {/* <Link
                                href={`/[lang]/craft-beer`}
                                as={'/link'}>
                                <a href="#">Details</a>
                            </Link> */}
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <button
        className={'swiper-button-next  d-none d-md-block'}
        aria-label='next'
      >
        <img src='/static/icons/arrow-white.svg' data-arrow='right' alt='' />
      </button>
    </div>
  );
};
export default BeerSlider;
