const dics = {
  homepage: {
    firstArmenian: {
      en: 'First Armenian',
      hy: 'Առաջին Հայկական',
    },
    dargettNews: {
      en: 'Dargett News',
      hy: 'Դառգետ Նորություններ',
    },
    breakingNews: {
      en: 'Some Breaking News About Dargett',
      hy: 'Հրատապ նորություններ՝ Դառգետի մասին',
    },
    typesOfMasterpiece: {
      en: 'types of bottled masterpiece',
      hy: 'տեսակի շշալցված գլուխգործոց',
    },
    masterpieceDescription: {
      en: 'We craft our beer utilizing exclusively high quality ingredients and applying the latest technologies of brewing.<br/><br/>In order to bring your favourite beer even closer to you, we have introduced to the market the bottled versions of the most popular types.',
      hy: 'Մենք արտադրում ենք  գարեջուր՝ օգտագործելով բացառապես բարձր որակի բաղադրիչներ և կիրառելով գարեջրի արտադրության նորագույն տեխնոլոգիաներ:<br/><br/>Այժմ կարող եք վայելել ձեր սիրելի գարեջուրն էլ ավելի հաճախ, քանի որ մենք շուկային ենք ներկայացրել  ամենահայտնի տեսակների շշալցված տարբերակները:',
    },
    dargettInTheCity: {
      en: 'Dargett In the City',
      hy: 'Դառգետը քաղաքում է',
    },
    dargettMission: {
      en: 'Our mission is to bring Craft beer culture closer to Armenian people, check out our progress.',
      hy: 'Մեր առաքելությունն է Հայաստանում տարածել քրաֆտ գարեջրի մշակույթը: Հետևեք մեր առաջընթացին:',
    },
    craftBeerDescription: {
      en: 'Dargett remains a small independent craft brewery, staying true to the craft ideology. We only use the highest quality ingredients that we can source from all over the world, all our beers are unpasteurized, unfiltered, and brewed in traditional methods to achieve a perfect serve.',
      hy: 'Դառգետը շարունակում է լինել փոքր  քրաֆթ գարեջրատուն, որը հավատարիմ է մնում քրաֆթի գաղափարախոսությանը: Գարեջուրը պատրաստվում է գարեջրագործության մեջ կիրառվող արտադրական վերջին տեխնոլոգիաներին համապատասխան՝ օգտագործելով միայն բարձր որակ ունեցող բաղադրիչներ, որոնք մեզ են հասնում ամբողջ աշխարհից։ Դառգետ  գարեջրի որևէ  տեսակ պաստերիզացված և ֆիլտրված չէ․ Այն պատրաստվում է  ավանդական մեթոդով,  հասնելով  կատարելության։',
    },
    footerMore: {
      en: 'More',
      hy: 'More',
    },
  },
  aboutPage: {
    paragraph1: {
      en: `Dargett opened its doors to the public in Spring of 2016 as Armenia’s first brewpub. Our mission statement was simple; to bring the craft beer culture to the Armenian consumer, offering a wide selection of craft beers in the highest quality possible. 
            <br/><br/>
            Since the opening of Dargett Brewpub, we have experienced massive growth. As a result, we expanded our operations and recently completed the construction of our new brewery.
            <br/><br/>
            Dargett remains a small independent craft brewery, staying true to the craft ideology. We only use the highest quality ingredients that we can source from all over the world, all our beers are unpasteurized, unfiltered, and brewed in traditional methods to achieve a perfect serve.
            `,
      hy: `2016 թվականի գարնանը Դառգետն իր դռները բացեց  հասարակության առջև որպես առաջին քրաֆտ գարեջրատունը Հայաստանում: Մեր առաքելությունը կարելի է ամփոփել մի քանի բառով՝ հասցնել քրաֆթ գարեջրի մշակույթը հայ սպառողին՝ առաջարկելով հնարավորինս բարձր որակի  գարեջրի մեծ տեսականի:
            <br/><br/>
            Մեր գարեջրի  պահանջարկից ելնելով, առաջացավ ընդլայնման անհրաժեշտություն:  2017 թվականին մեկնարկեց  Դառգետ գարեջրի գործարանի շինարարությունը, որը ավարտվեց 2018 թ.-ի սեպտեմբերին: Այդ պահից սկսած  Դառգետ գարեջրի տեսակները հասանելի դարձան մանրածախ վաճառքի և ավելի քան 350 HoReCa սեգմենտի կետերում:
            <br/><br/>
            Դառգետը շարունակում է լինել փոքր   գարեջրատուն, որը հավատարիմ է մնում քրաֆթի գաղափարախոսությանը: Գարեջուրը պատրաստվում է գարեջրագործության մեջ կիրառվող արտադրական վերջին տեխնոլոգիաներին համապատասխան՝  օգտագործելով միայն բարձր որակ ունեցող բաղադրիչներ, որոնք մեզ են հասնում ամբողջ աշխարհից։ Մեր գարեջրի որևէ տեսակ  պաստերիզացված և ֆիլտրված չէ․, այն   պատրաստվում է ավանդական մեթոդով,  հասնելով  կատարելության։
            `,
    },
    paragraph2: {
      en: 'We handcraft our beer applying international standards of brewing, using high quality ingredients and cutting-edge equipment. Each batch of the brew has its own story behind; each type of beer has its own success formula, to deliver the utmost pleasure of enjoying craft beer to our Dargetters (people who love Dargett beer).',
      hy: 'Մենք կերտել ենք մեր գարեջուրը՝ կիրառելով գարեջրագործության միջազգային ստանդարտներ, օգտագործելով բարձրորակ բաղադրիչներ և առաջադեմ սարքավորումներ: Գարեջրի յուրաքանչյուր խմբաքանակ ունի իր պատմությունը․ գարեջրի յուրաքանչյուր տեսակ իր հաջողության սեփական բանաձևը, որպեսզի կարողանանք մատուցել գարեջուր վայելելու հաճույքը մեր Դառգեթասերներին (մարդիկ, ովքեր սիրում են Dargett գարեջուր):',
    },
    paragraph3: {
      en: 'We stand out from industrial beer producers. We play with flavors and aromas and “give a birth” to exclusively new (i.e. Apricot Ale) or one of the over 100 worldwide known types of craft beer. We revolutionize the perception of the term “beer”, and show that its more than just lager, dark and wheat. Thus, our ultimate goal is to revolutionize the beer industry in our country and we are on this complicated and inspiring journey right now!',
      hy: 'Մենք առանձնանում ենք արդյունաբերական մաշտաբով գարեջուր արտադրողներից: Մենք խաղում ենք համերի և բույրի հետ և «կյանքի ենք բերում» բացառապես նորը (օրինակ՝ ծիրանի համով Apricot Ale-ը) կամ աշխարհում հայտնի 100-ից ավել տեսակ կրաֆտ գարեջրից մեկը: Մենք հեղափոխում ենք «գարեջուր» տերմինի ընկալումը և ցույց ենք տալիս, որ այն ավելին է, քան պարզապես բաց կամ մուգ ու ցորենից պատրաստված խմիչք: Այսպիսով, մեր վերջնական նպատակը մեր երկրում գարեջրի արտադրության հեղափոխությունն է, և մենք հիմա այս բարդ և ոգեշնչող ուղին ենք բռնել:',
    },
    paragraph4: {
      en: `We have turned our size to the biggest advantage that we have. We freely experiment, create and test new recipes, constantly enhance and work on our brews to make our products masterpieces.
            <br/><br/>
            Our mission is bringing unfiltered and unpasteurised beers closer to the consumers and keeping it fresh.`,
      hy: `Մենք մեր չափը դարձրեցինք մեր ամենամեծ առավելությունը: Մենք ազատ փորձարկում, ստեղծում և համտեսում ենք նոր բաղադրատոմսեր, անընդհատ ուժեղացնելով և աշխատելով մեր գարեջրի տեսակների վրա, որպեսզի նրանք դառնան գլուխգործոցներ:
            <br/><br/>
            Մեր առաքելությունն է ներկայացնել գարեջուր, որը չի լինի պաստերիզացված և ֆիլտրված կլինի, և որը սպառողի համար կլինի ավելի դուրեկան և թարմ։`,
    },
    productRange: {
      en: 'Product Range',
      hy: 'Տեսականի',
    },
    productRangeDescripton: {
      en: `Dargett Brew-Pub serves<br/>7 types of bottled masterpiece.`,
      hy: `Դառգետ Գարեջրատունը առաջարկում է<br/>7 տեսակի շշալցված գլուխգործոց`,
    },
  },
  brewpubPage: {
    description: {
      en: `Opened its doors in 2016, since then the brewpub has become a flagship and benchmark for the entire market. It initiated and shaped Armenian craft community and constantly delivers the utmost service to its visitors.
            <br/><br/>
            The brewpub is specially designed to experience the complete craft culture and enjoy its every detail be it music, interior design, service, food or drinks. Talking about the latter, we are absolutely proud to serve over 20 types of handmade craft beer utilizing high-quality ingredients and the best traditions of brewing. Our Craft brewery gives visitors an opportunity to immerse themselves in the sub-culture of revolutionary beer and taste the best of traditional craft beer types like IPA, American Pale, Blond Ale, Lagers and many more.`,
      hy: `2016-ին բացելով իր դռները, Դառգետ գարեջրատունը կարճ ժամանակահատվածում դարձավ շուկայում առաջատար ու նոր չափանիշ: Այն նախաձեռնել և ձևավորել է հայկական քրաֆթ համայնքը և մատուցում է անգերազանցելի սպասարկում իր այցելուներին:
            <br/><br/>
            Դառգետ գարեջրատունը հատուկ մշակված է ամբողջական քրաֆթ  մշակույթը ներկայացնելու համար և այն վայելելու իրեն հատուկ երաժշտությամբ, ինտերիեր դիզայնով, սպասարկմամբ, սնունդով և խմիչքներով: Խոսելով վերջինիս մասին, մենք բացարձակապես հպարտ ենք, որ առաջարկում ենք շուրջ 20 տեսակի  ձեռակերտ քրաֆթ  գարեջուր, օգտագործելով բարձրորակ բաղադրիչներ եւ կիրառելով գարեջրագործության լավագույն ավանդույթները: Մեր քրաֆթ  գարեջրատունը այցելուներին հնարավորություն է տալիս ընկղմվել հեղափոխական քրաֆթ գարեջրի ենթամշակույթի մեջ և համտեսել լավագույն ավանդական քրաֆթ գարեջրի տեսակները, ինչպիսիք են IPA-ն, American Pale Ale-ը, Blond Ale-ը, Lagers-ը և շատ ավելին:`,
    },
    ourMenu: {
      en: 'Our Menu',
      hy: 'Մենյու',
    },
    ourMenuDescription: {
      en: 'Dargett brew-pub also provide big food menu, with special dishes that will make your visit even better.',
      hy: 'Դառգետ Գարեջրատունը նաև առաջարկում  է բազմազան սննդի մենյու` հատուկ ուտեստներով, որոնք կդարձնեն Ձեր այցը ավելի հաճելի:',
    },
    brewpubServes: {
      en: 'Dargett Brew-Pub serves',
      hy: 'Դառգետ Գարեջրատունը առաջարկում է',
    },
    typesOfHandMadeCraftBeer: {
      en: 'types of hand made craft beer',
      hy: 'տեսակի արհեստագործական քրաֆթ  գարեջուր',
    },
    typesDescription: {
      en: 'Utilizing  high-quality ingredients, the best traditions of brewing and the latest Italian technology. <br/><br/>Our Craft brewery gives visitors an opportunity to immerse themselves in the sub-culture of revolutionary beer and taste the best of traditional craft beer types like IPA, American Pale, Blond Ale, Lager and many more. ',
      hy: 'Օգտագործելով բարձրորակ բաղադրիչներ եւ գարեջրի լավագույն ավանդույթները:<br/><br/>Մեր քրաֆթ  գարեջրատունը այցելուներին հնարավորություն է տալիս ընկղմվել հեղափոխական քրաֆթ գարեջրի ենթամշակույթի մեջ և համտեսել լավագույն ավանդական քրաֆթ գարեջրի տեսակները, ինչպիսիք են IPA-ը, American Pale Ale-ը, Blond Ale-ը, Lager-ները և շատ ավելին:',
    },
    readTheNextOne: {
      en: '',
      hy: '',
    },
    dargettBreweryDescription: {
      en: `Dargett was founded as a beacon of revolutionary craft beer. We believe in experimenting, innovating, trying, failing, and succeeding!`,
      hy: `Դառգետը ստեղծվել է որպես հեղափոխական քրաֆթ գարեջրի փարոս-ուղեցույց: Մենք հավատում ենք փորձելուն, նորարարական մտքեր ներդնելուն, փորձարկելուն, ձախողելուն և հաջողության հասնելուն:`,
    },
  },
  breweryPage: {
    paragraph1: {
      en: 'Following the high demand for our beer in the brewpub, the need of expansion arised. Thus, we started the construction of our brewery and completed it in September 2018. Since then, our beers have become more available for our consumers.',
      hy: 'Դառգետ  գարեջրի բարձր պահանջարկից ելնելով, առաջացավ արտադրական հզորությունների ընդլայնման անհրաժեշտություն: 2017 թվականին  մեկնարկած գարեջրի գործարանի շինարարությունը  ավարտվեց 2018 թ.-ի սեպտեմբերին: Արտադրության գործարկումից  սկսած մեր գարեջրի տեսակները ավելի հասանելի դարձան սպառողին:',
    },
    mashing: {
      en: 'Mashing',
      hy: 'Ածիկի խառնում ջրին ',
    },
    mashingDescription: {
      en: 'Now malt is added to heated, purified water and, through a carefully controlled time and temperature process, the malt enzymes break down the starch to sugar, and the complex proteins of the malt break down to simpler nitrogen compounds. The mashing takes place in a large round tank called a "mash mier" or "mash tun", and requires careful temperature control. ',
      hy: 'Տաքացված և մաքրված ջրին ավելացվում է ածիկ: Ժամանակի ու ջերմաստիճանի խիստ վերահսկման ներքո ածիկի ֆերմենտները տրոհում են օսլան շաքարի, իսկ ածիկի բարդ սպիտակուցները տրոհվում են ազոտի պարզ միացությունների: Ածիկի և ջրի խառնումը  տեղի է ունենում մեծ շրջանաձև տարրայի մեջ, որը կոչվում է «քաղցուի թաղար» կամ «տրորման աման», իսկ այդ գործընթացը պահանջում է ջերմաստիճանի խիստ վերահսկողություն:',
    },
    lautering: {
      en: 'Lautering',
      hy: 'Քամելու գործընթաց',
    },
    lauteringDescription: {
      en: `The mash is transferred to a straining or "lautering" vessel, usually cylindrical, with a slotted false bottom two to five cm above the true bottom.
            <br/><br/>
            The liquid extract drains through the false bottom and is run off to the brew kettle. This extract, a sugar solution called "wort", is not yet beer. Water is "sparged" or sprayed through the grains to wash out as much of the extract as possible. The "spent grains" are removed and sold for cattle feed.`,
      hy: `Խառնուրդը լցվում է «քամող» անոթի մեջ, որը, հիմնականում, գլանաձև անոթ է՝ կտրտված կեղծ հատակով՝ 2-ից 5 սմ բարձր իրական հատակից:<br/>
            Հյութը հոսում է կեղծ հատակից եփման կաթսայի մեջ: Այս հյութը՝ «քաղցու» կոչվող շաքարի լուծույթը, դեռ գարեջուր չէ: Հատիկների վրա ջուր են «ցողում»՝ հնարավորինս շատ հյութ ստանալու նպատակով: «Քամված» հատիկները հեռացվում են և վաճառվում որպես անասնակեր:`,
    },
    boiling: {
      en: 'Boiling',
      hy: 'Եփում',
    },
    boilingDescription: {
      en: 'Boiling takes place in a huge cauldron-like brew kettle under carefully controlled conditions. The process to obtain the desired extract from the hops usually takes about two hours. The hop resins contribute flavour, aroma and bitterness to the brew. Once the hops have flavoured the brew, they are removed. Sometimes, highly fermentable syrup may be added to the kettle. Undesirable protein substances which have survived the journey from the mash mixer are coagulated, leaving the wort clear.',
      hy: `Եռացնելու գործընթացը տեղի է ունենում հսկայական կաթսայի մեջ՝ խիստ վերահսկվող պայմաններում: Գայլուկից ցանկալի հյութը ստանալու գործընթացը, սովորաբար, մոտավորապես երկու ժամ է տևում: Գայլուկի խեժը գարեջրին համ, հոտ և դառնություն է հաղորդում: Գարեջրին համ հաղորդելուց հետո այն հեռացվում է: Երբեմն կաթսայի մեջ կարող է խմորվող սիրոպ ավելացվել: Անցանկալի սպիտակուցային նյութերը, որոնք դեռևս ածիկն ու ջուրը խառնելու փուլից պահպանվել են, վերածվում են թանձր նյութի, և մնում է մաքուր քաղցուն:`,
    },
    cooling: {
      en: 'Cooling',
      hy: 'Սառչելու փուլ',
    },
    coolingDescription: {
      en: 'After the beer has taken on the flavour of the hops, the wort then goes to the hot wort tank. It’s then cooled, usually in an apparatus called a plate cooler. As the wort and a coolant flow past each other on opposite sides of stainless steel plates, the temperature of the wort drops from boiling to about 10°C to 15°C in a few seconds.',
      hy: 'Հենց որ գայլուկը գարեջրին համ է հաղորդում, քաղցուն լցվում է տաք տարրայի մեջ: Այնուհետև, այն սառչում է, որպես կանոն, սալիկավոր սառնարան կոչվող սարքի մեջ: Երբ քաղցուն և սառեցնող նյութը հոսում են իրար կողքով՝ չժանգոտվող պողպատե սալիկների հակառակ կողմերով, քաղցուի ջերմաստիճանը մի քանի վայրկյանում նվազում է՝ եռման ջերմաստճանից հասնելով մոտավորապես 10°C - 15°C-ի:',
    },
    fermentation: {
      en: 'Fermentation',
      hy: 'Խմորում',
    },
    fermentationDescription: {
      en: 'Once the wort has been cooled, it is moved to a fermentor. Next yeast is added and the fun begins! From the moment the yeast is added it gets to work eating the sugars that were created during the mash. As they consume the sugar, the yeast expels carbon dioxide and alcohol, as well as a variety of flavor compounds that vary greatly depending on several variables such as the specific strain of yeast, and fermentation temperature of the beer. Fermentation time can vary greatly, from a few days for a simple ale, to over a month for lagers.',
      hy: 'Հենց որ քաղցուն սառչում է, դրան ավելացվում է խմորիչը, և «զվարճանքը» սկսված է: Ավելացվելուն պես խմորիչը սկսում է կլանել ածիկը ջրին խառնելու ժամանակ առաջացած շաքարները: Այս ընթացքում խմորիչը դուրս է մղում ածխաթթու գազ և ալկոհոլ, ինչպես նաև մի շարք համային նյութեր, որոնք մեծապես տարբերվում են՝ կախված մի շարք գործոններից, ինչպես օրինակ՝ խմորիչի լարվածությունից և գարեջրի խմորման ջերմաստիճանից: Խմորման ժամանակահատվածը նույնպես կարող է մեծապես տարբերվել՝ մի քանի օր  Ale (էյլ) տեսակի գարեջրի դեպքում և մոտավորապես մեկ ամիս Lager (լագեր) տեսակի դեպքում:',
    },
    matuation: {
      en: 'Maturation',
      hy: 'Հասունացում',
    },
    matuationDescription: {
      en: 'Maturation includes all transformations between the end of primary fermentation and the removal of yeast from the beer in preparation for packaging',
      hy: 'Հասունացման փուլն ընդգրկում է բոլոր վերափոխումները նախնական խմորման ավարտից մինչև գարեջրից խմորիչի հեռացնելը, որին հաջորդում է գարեջուրը շշալցնելու կամ տակառների մեջ լցնելու փուլը:',
    },
    packaging: {
      en: 'Packaging',
      hy: 'Շշալցում կամ տակառների մեջ լցնելու գործընթաց',
    },
    packagingDescription: {
      en: 'After all the stages are completed, the beer is being packaged either into 0.33l bottles or sterilised kegs.',
      hy: 'Երբ բոլոր փուլերն ավարտված են, գարեջուրը լցվում է կա՛մ 0.33լ շշերի, կա՛մ էլ ստերիլիզացված տակառների մեջ:',
    },
    ingredients: {
      en: '',
      hy: '',
    },
    malt: {
      en: 'Malt',
      hy: 'Ածիկ',
    },
    maltDescription: {
      en: 'Malt is the soul of beer, as it contributes flavor, aroma, color and body. It’s a cereal grain, generally barley, that has been partially germinated then kilned or roasted. Roasted specialty malts impart color and unique flavors like toffee, nuts, chocolate, coffee, bread, raisin, prune and much more.',
      hy: 'Ածիկը գարեջրի հոգին է, քանի որ այն համ, հոտ, գույն և «մարմին» է հաղորդում վերջինիս: Այն հացահատիկ է՝ հիմնականում, գարի, որը մի փոքր ծլարձակել է, այնուհետև եփվել և բովվել: Բոված ածիկը ձեռք է բերում գույն և առանձնահատուկ համ՝ նման իրիսի, ընդեղենի, շոկոլադի, սուրճի, հացի, չամչի, սալորի և այլ համերի:',
    },
    hops: {
      en: 'Hops',
      hy: 'Գայկուկ',
    },
    hopsDescription: {
      en: 'Craft brewers use hops as chefs use spices. Hop bines produce pungent flowers that add aroma, flavor and bitterness to craft beer. Hop bitterness and malt sweetness are the two crucial balance wheels of beer. There are hundreds of hop varieties, each with their own bittering, flavoring and aromatic properties. Common flavor and aromatic descriptors include floral, citrus, fruity, grassy, pine/forest, herbal/spicy, etc. ',
      hy: 'Քրաֆթ գարեջուր արտադրողներն օգտագործում են գայլուկ նույն նպատակով, ինչ խոհարարներն օգտագործում են համեմունքներ օգտագործելիս: Գայլուկներն ունեն դառը ծաղիկներ, որոնք հոտ, համ և դառնություն են հաղորդում քրաֆթ գարեջրին: Գայլուկների դառնությունը և ածիկի քաղցրությունը գարեջրի համի հավասարակշռությունը պահպանող երկու անիվներն են: Գոյություն ունեն գայլուկի հարյուրավոր տեսակներ՝ յուրաքանչյուրն իր համային և հոտային առանձնահատկություններով: Հիմնական համային և հոտային բնութագրիչներն են՝ ծաղկային, ցիտրուսային, մրգային, խոտային, սոճու/անտառային, խոտաբույսային/համեմունքային և այլն:',
    },
    yeast: {
      en: 'Yeast',
      hy: 'Խմորիչ',
    },
    yeastDescription: {
      en: 'You’re not the first one to enjoy the beer in your glass. During fermentation, yeast feasts on sugar and expels alcohol, carbon dioxide (CO2) and flavor compounds. The main point of differentiation in yeast types is lager vs. ale yeast. Within these two broad categories are a wide variety of yeasts. Yeast may impart distinct flavors to beer, or it can be more neutral. There are around 950 compounds that are thought to give beer its character and over 400 of them are produced by the yeasts. Bottle conditioned craft beer includes live yeast in the packaging, often resulting in a vitamin-rich sediment residing at the bottom of the bottle.',
      hy: 'Դուք միակը չեք, ով վայելում է գարեջուր իր գավաթում: Խմորման ընթացքում խմորիչը  կլանում է շաքարը և դուրս մղում ալկոհոլ, ածխաթթու գազ (CO2) և համային նյութեր: Խմորիչի հիմնական տեսակներն են՝ լագեր և էյլ: Այս երկու հիմնական տեսակներն ունեն մի շարք այլ տեսակներ: Խմորիչը կարող է տարբեր համեր հաղորդել գարեջրին կամ  կարող է ավելի չեզոք ազդեցություն ունենալ: Համարվում է, որ գարեջուրն իր հատկանիշները ձեռք է բերում մոտավորապես 950 միացությունների շնորհիվ, որոնցից մոտավորապես 400-ը արտադրվում են խմորիչի կողմից: Այն դեպքում, երբ գարեջրի գազավորումը տեղի է ունենում խմորիչի միջոցով խմորումից հետո, շշի մեջ առկա են լինում կենդանի խմորիչներ, որոնք, հիմնականում, վերածվում են վիտամիններով հարուստ նստվածքի:',
    },
    water: {
      en: 'Water',
      hy: 'Ջուր',
    },
    waterDescription: {
      en: 'Terroir is a common term in the wine world, but did you know that local climates and geology affect beer? Depending on the region, water has variable mineral content that contributes to the expression of malt, hops and the overall flavor of the finished beer. Various historical beer styles emerged in different regions (Germany, England, Belgium, USA) have their unique requirements of the water profile that must be kept. Our local spring water has the perfect components to produce unique Armenian craft beer.',
      hy: 'Տերուարը (ֆր.terroir)  տարածված տերմին է գինու աշխարհում, սակայն արդյոք դուք գիտե՞ք, որ տեղական կլիման և երկրաբանական տվյալները ազդում են նաև գարեջրի վրա: Կախված տարածաշրջանից, ջուրն ունի փոփոխական հանքային կազմ, որն ազդում է ածիկի, գայլուկի տված համերի բացմանը, ինչպես նաև գարեջրի վերջնական համի վրա: Այսպիսով, գարեջրի տարբեր պատմական ոճեր են առաջանում տարբեր տարածաշրջաններում (Գերմանիա, Անգլիա, Բելգիա, ԱՄՆ), որոնք ունեն ջրի հանդեպ հատուկ պահանջներ և այդ պահանջները պետք է պահպանվեն: Հայաստանյան  աղբյուրի ջուրը հիանալի բաղադրիչներ ունի, ինչը թույլ է տալիս  արտադրել յուրահատուկ հայկական քրաֆտ գարեջուր:',
    },
    brewpubDescription: {
      en: 'Dargett was founded as a beacon of revolutionary craft beer. We believe in experimenting, innovating, trying, failing and succeeding!',
      hy: 'Դառգետը ստեղծվել է որպես հեղափոխական քրաֆթ գարեջրի փարոս-ուղեցույց: Մենք հավատում ենք փորձելուն, նորարարական մտքեր ներդնելուն, փորձարկելուն, ձախողելուն և հաջողության հասնելուն:',
    },
  },
  craftBeerPage: {
    perfectlyMatchableWith: {
      en: 'Perfectly matchable with',
      hy: 'Համադրելի է հետևյալ ուտեստների հետ.',
    },
  },
  ourBlogPage: {
    description: {
      en: 'We want our community to stay updated about our brand and the craft industry generally. Check below to see our latest blog posts',
      hy: 'Մենք ուզում էնք բոլոր դառգետասերները տեղեկացված լինեն բրենդի և ընդհանուր քրաֆթ գարեջրագործության նորությունների մասին: Ստորև նարկայացված են մեր վերջին բլոգային հաղորդագրությունները:',
    },
  },
  joinUsBlock: {
    title: {
      en: 'Join us in our mission',
      hy: 'Միացի՛ր մեզ',
    },
    description: {
      en: 'Become Partner',
      hy: 'Դարձիր գործընկեր',
    },
    button: {
      en: 'Join',
      hy: 'Միանալ',
    },
  },
  partnershipPage: {
    description: {
      en: '',
      hy: '',
    },
    becomePartner: {
      en: 'Become Partner',
      hy: 'Դարձիր գործընկեր',
    },
    becomePartnerDescription: {
      en: 'And lets bring craft beer culture closer to Armenian people together.',
      hy: 'Եկեք միասին տարածենք քրաֆթ գարեջրի մշակույթը Հայաստանում։',
    },
  },
  careersPage: {
    description: {
      en: 'Behind any successful brand or story are the heroes who keep adding benefit to something big they are united by. Together at Dargett we craft hard, laugh a lot, brainstorm non-stop and change the perception of beer constantly. Join our Great Minds’ team!',
      hy: 'Ցանկացած հաջողված ապրանքանիշի կամ պատմության հետևում  կանգնած են այն հերոսները, ովքեր շարունակաբար օժանդակում են այն մեծ գաղափարի իրագործմանը, որը նրանց բոլորին միավորել է։ Դարգետում մենք ստեղագործում ենք միասին, ծիծաղում ենք շատ, կազմակերպում ենք անվերջ մտագրոհներ և  անընդհատ փոխում ենք գարեջրի մասին ընկալումը: Միացեք մեր Մեծ Մտածողների խմբին:',
    },
    dontFindYours: {
      en: '',
      hy: '',
    },
    dontFindYoursDescription: {
      en: '',
      hy: '',
    },
    attachCv: {
      en: '',
      hy: '',
    },
  },
  careersInnerPage: {
    posted: {
      en: '',
      hy: '',
    },
    atBrepub: {
      en: '',
      hy: '',
    },
    atBrewery: {
      en: '',
      hy: '',
    },
    applyForThisPosition: {
      en: '',
      hy: '',
    },
    deadline: {
      en: '',
      hy: '',
    },
    jobDesription: {
      en: '',
      hy: '',
    },
    jobResponsibilities: {
      en: '',
      hy: '',
    },
    requiredQualifications: {
      en: '',
      hy: '',
    },
    additionalInformation: {
      en: '',
      hy: '',
    },
    position: {
      en: '',
      hy: '',
    },
    location: {
      en: '',
      hy: '',
    },
  },
  contactUsPage: {
    title: {
      en: '',
      hy: '',
    },
    description: {
      en: '',
      hy: '',
    },
    brewpubAddress: {
      en: '',
      hy: '',
    },
    breweryAddress: {
      en: '',
      hy: '',
    },
    reservation: {
      en: '',
      hy: '',
    },
  },
  menu: {
    title: {
      en: '',
      hy: '',
    },
    brewpubDescription: {
      en: '',
      hy: '',
    },
  },
  mediaAssetsPage: {
    description: {
      en: '',
      hy: '',
    },
    blackLogo: {
      en: '',
      hy: '',
    },
    blackSymbol: {
      en: '',
      hy: '',
    },
    whiteSymbol: {
      en: '',
      hy: '',
    },
    whiteLogo: {
      en: '',
      hy: '',
    },
  },
  dargett: {
    en: 'Dargett',
    hy: 'Դառգետ',
  },
  home: {
    en: 'Home',
    hy: 'Գլխավոր',
  },
  brewpub: {
    en: 'Brewpub',
    hy: 'Գարեջրատուն',
  },
  craftBeer: {
    en: 'Craft Beer',
    hy: 'Քրաֆթ Գարեջուր',
  },
  aboutUs: {
    en: 'About Us',
    hy: 'Մեր Մասին',
  },
  brewery: {
    en: 'Brewery',
    hy: 'Գարեջրի Գործարան',
  },
  ourBlog: {
    en: 'Our Blog',
    hy: 'Նորություններ',
  },
  partnership: {
    en: 'Partners',
    hy: 'Գործընկերներ',
  },
  careers: {
    en: 'Careers',
    hy: 'Կարիերա',
  },
  contactUs: {
    en: 'Contact Us',
    hy: 'Կապ',
  },
  explore: {
    en: 'Explore',
    hy: 'Ավելին',
  },
  exploreMore: {
    en: 'Explore More',
    hy: 'Ավելին',
  },
  more: {
    en: 'More',
    hy: 'Ավելին',
  },
  readMore: {
    en: 'Read More',
    hy: 'Ավելին',
  },
  details: {
    en: 'Details',
    hy: 'Ավելին',
  },
  apply: {
    en: 'Apply',
    hy: 'Apply',
  },
  fullname: {
    en: 'Fullname',
    hy: 'Fullname',
  },
  email: {
    en: 'E-Mail',
    hy: 'E-Mail',
  },
  other: {
    en: 'Other',
    hy: 'Other',
  },
  message: {
    en: 'Message',
    hy: 'Message',
  },
  linkedinProfile: {
    en: 'Linkedin Profile',
    hy: 'Linkedin Profile',
  },
  becomePartner: {
    en: 'Become Partner',
    hy: 'Դարձիր գործընկեր',
  },
  share: {
    en: 'Share',
    hy: 'Share',
  },
  tweet: {
    en: 'Tweet',
    hy: 'Tweet',
  },
  amd: {
    en: 'AMD',
    hy: 'AMD',
  },
  alcohol: {
    en: 'ALC',
    hy: 'ALC',
  },
  ibu: {
    en: 'IBU',
    hy: 'IBU',
  },
  yes: {
    en: 'Yes',
    hy: 'Այո',
  },
  no: {
    en: 'No',
    hy: 'Ոչ',
  },
  English: {
    en: 'ENG',
    hy: 'ENG',
    ru: 'ENG',
  },
  Armenian: {
    en: 'ՀԱՅ',
    hy: 'ՀԱՅ',
    ru: 'ՀԱՅ',
  },
  Russian: {
    en: 'RUS',
    hy: 'RUS',
    ru: 'RUS',
  },
  noLater: {
    en: 'No, I’ll come back later',
    hy: 'Ոչ, ես ավելի ուշ կգամ',
  },
  mediaAssets: {
    en: 'Media Assets',
    hy: 'Media Assets',
  },
  areYouOfLegalAge: {
    en: 'Are you of a legal drinking age?',
    hy: 'Դուք չափահա՞ս եք',
  },
  'buy-our-beer': {
    en: 'Buy Our Beer',
    hy: 'Գնեք մեր գարեջուրը',
  },
  dargettized: {
    en: 'Dargettized',
    hy: 'Դառգետացում',
  },
  'brew-pub': {
    en: 'Brew Pub',
    hy: 'Գարեջրատուն',
  },
  'soups-&-salads': {
    en: 'Soups & Salads',
    hy: 'Soups & Salads',
  },
  'starters-&-platters': {
    en: 'Starters & Platters',
    hy: 'Starters & Platters',
  },
  'main-dishes': {
    en: 'Main Dishes',
    hy: 'Main Dishes',
  },
  'burgers-&-sandwiches': {
    en: 'Burgers & Sandwiches',
    hy: 'Burgers & Sandwiches',
  },
  pizza: {
    en: 'Pizza',
    hy: 'Pizza',
  },
  desserts: {
    en: 'Desserts',
    hy: 'Desserts',
  },
  reservation: {
    en: 'Reservation',
    hy: 'Reservation',
  },
};
export default dics;
