/* eslint-disable jsx-a11y/anchor-is-valid */
import dictionary from '../../assets/js/dictionary'
import Logo from '../Logo'

const LegalAgeModal = ({
    setAdult,
    lang,
}) => {
    const confirm = () => {
        localStorage.setItem('adult', true)
        setAdult(true)
    }

    const decline = (e) => {
        e.preventDefault()
        localStorage.setItem('adult', false)
        setAdult(false)
    }

    return (
        <div id="legal-age-modal">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Logo fill="white"/>
                        <div>
                            <p>{dictionary.areYouOfLegalAge[lang]}</p>
                        </div>
                        <button 
                            className="round primary-outline"
                            onClick={confirm} aria-label="round primary">
                                {dictionary.yes[lang]}
                        </button>
                        <div>
                            <button 
                            aria-label="later"
                                onClick={decline}>
                                    {dictionary.noLater[lang]}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LegalAgeModal