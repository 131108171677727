import { useState, useEffect } from 'react'

export default function useWindowSize () {
    const [viewport, setViewport] = useState({
        width: 0,
        height: 0
    })

    const onResize = () => {
        setViewport({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    useEffect(() => {
        onResize()
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return viewport
}