import React from "react";
import { useModal } from "../helpers/modal_provider";
import { useLang } from "../helpers/language";

export default function Modal(props) {
  const { lang } = useLang();
  let { modalToggle, open } = useModal();

  const ModalTitle = {
    en: "Order Dargett",
    ru: "Закажи Даргет",
    hy: "Պատվիրիր Դառգետ",
  };
  const MainTitle = {
    en: "Now!",
    ru: "Cейчас!",
    hy: "Հիմա!",
  };

  return (
    <>
      <div className={`modal ${open ? "show" : ""}`}>
        <div className="modal__window">
          <img
            src="/static/icons/close-dark.svg"
            className="modal__window-close dms-cursor--close pointer"
            onClick={modalToggle}
            alt="mobile menu hamburger"
          />
          <h1 className="title">{ModalTitle[lang]}</h1>
          <a
            href={"https://glovoapp.com/am/ru/yerevan/dargett-beershop/"}
            rel="_blank"
          >
            <div className="modal__content">
              {/* <img src='/static/images/glovo.png' alt='glovo' /> */}
              <img src="/static/images/glovo2.webp" alt="glovo" />
              <h2>{MainTitle[lang]}</h2>
              <h2>{MainTitle[lang]}</h2>
            </div>
          </a>
        </div>
      </div>
    </>
  );
}
