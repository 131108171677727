import { useEffect, useState } from "react";
import { Link ,useLocation} from "react-router-dom";
import BeerSlider from "../beer-slider/BeerSlider";
import Logo from '../Logo';
import "./site-menu.scss";
import { withDictionary, withLanguage } from "../../assets/js/contexts";
import { useLang } from "../../helpers/language";

const routes = [
  { key: { en: "About Us", ru: "О нас", hy: "Մեր մասին" }, path: "/about-us" },
  { key: { en: "Brewery", ru: "Пивоварня", hy: "Գործարան" }, path: "/brewery" },
  { key: { en: "Brewpub", ru: "Брюпаб", hy: "Գարեջրատուն" }, path: "/brewpub" },
  { key: { en: "Beer", ru: "Пиво", hy: "Գարեջուր" }, path: "/beer" },
  { key: { en: "Special", ru: "Special", hy: "Special" }, path: "/special" },
  { key: { en: "Cider", ru: "Сидр", hy: "Սիդր" }, path: "/cider" },
  { key: { en: "Our Blog", ru: "Блог", hy: "Բլոգ" }, path: "/blog" },
  { key: { en: "Partners", ru: "Партнеры", hy: "Գործընկերներ" }, path: "/partnership" },
  { key: { en: "Contact Us", ru: "Контакты", hy: "Կապ Մեզ հետ" }, path: "/contact-us" },
];

const SiteMenu = ({ toggleMenu, dictionary }) => {
  // eslint-disable-next-line
  const [dark, setDark] = useState(false);
  const { lang } = useLang();
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };
  useEffect(() => {
    // getBottles();
  }, []);
  return (
    <div id="site-menu">
      <div className="container-fluid h-100">
        <div className="row align-items-center h-100 p-relative">
          <div className="col-12">
            <div className="row align-items-center ps-0 ps-sm-5  justify-content-center justify-content-sm-start">
              <div className="col-md-8 h-100 row  justify-content-center justify-content-sm-start">

                <button
                  data-action="close"
                  aria-label="close"
                  onClick={() => {
                    toggleMenu(false);
                  }}
                >
                  <img src="/static/icons/close-white.svg" alt="close icon" />
                </button>
                <div className="col-12 logo">
                  <Link to="/" onClick={()=>{toggleMenu(false)}}>
                  <Logo fill={dark ? "black" : "white"} />
                  </Link>
                </div>
                <div className="row col-12">
                  {routes.map((route, index) => (
                    <div
                      className="col-md-4 col-sm-6 nav-item text-center"
                      key={index}
                      // style={{
                      //   marginBottom: ![6, 7, 8].includes(index) ? 135 : 0,
                      // }}
                    >{route.path === '/contact-us' ? 
                      (<Link
                        to={`/contact-us?lang=${lang}&contact=other`}
                        onClick={()=>{toggleMenu(false)}}
                      >
                        <span className="section w-100 d-block breake-word" data-selected={
                          activeRoute(`${route.path}`)
                              ? true
                              : false
                        } >
                          {route.key[lang]}
                        </span>
                      </Link>) : (<Link
                        to={`${route.path}?lang=${lang}`}
                        onClick={()=>{toggleMenu(false)}}
                      >
                        <span className="section w-100 d-block breake-word" data-selected={
                          activeRoute(`${route.path}`)
                              ? true
                              : false
                        } >
                          {route.key[lang]}
                        </span>
                      </Link>)
                      }
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                  <BeerSlider toggleMenu={toggleMenu} lang={'en'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLanguage(withDictionary(SiteMenu));
