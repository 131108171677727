import moment from "moment";
import { Link } from "react-router-dom";
import "./footer.scss";
import { useLang } from "../../helpers/language";
import { withLanguage } from "../../assets/js/contexts";

const socialNetworks = {
  instagram: "https://www.instagram.com/dargettbrewpub/",
  facebook: "https://www.facebook.com/search/top?q=dargett%20brewpub",
};

const socialNetworksBrewery = {
  usa: {
    instagram: "https://www.instagram.com/dargett_usa/",
    facebook: "https://www.facebook.com/dargettusa",
  },
  russia: {
    instagram: "https://www.instagram.com/dargett_russia/",
    facebook: "https://www.facebook.com/Dargett-Russia-101584175319585",
  },
  armenia: {
    instagram: "https://www.instagram.com/dargett_craft_beer/",
    facebook: "https://www.facebook.com/DargettCraftBeer",
  },
};

const Footer = ({ dictionary }) => {
  const year = moment().format("YYYY");
  const { lang } = useLang();
  const links = [
    {
      key: {
        en: "Careers",
        ru: "Карьера",
        hy: "Աշխատանք",
      },
      path: `/contact-us?lang=${lang}&contact=career`,
    },
    {
      key: {
        en: "Partners",
        ru: "Сотрудничество",
        hy: "Համագործակցություն",
      },
      path: "/partnership",
    },
    {
      key: {
        en: "Contact us",
        ru: "Обратная связь",
        hy: "Կապ",
      },
      path: `/contact-us?lang=${lang}&contact=other`,
    },
    {
      key: {
        en: "Media Assets",
        ru: "Медиа",
        hy: "Մեդիա",
      },
      path: "/assets",
    },
  ];
  return (
    <footer>
      <div className="container">
        <div className="row justify-content-center">
          <div className="px-5 col-md-10 d-flex justify-content-center">
            <div className="footer-links">
              {links.map((link, index) => (
                <div className="footer-links-item" key={index}>
                  <Link // key={`footer-link-${link.key}`}
                    to={`${link.path}`}
                    // as={`/${lang}${link.path}`}
                    aria-label="social"
                  >
                    {link.key[lang]}{" "}
                  </Link>
                </div>
              ))}{" "}
            </div>
          </div>

          <div className="px-5 col-md-10 flex-column align-items-center d-flex justify-content-center">
            <div className="footer-social d-flex justify-content-center flex-column">
              <div className="social social-brew mt-2">
                <span>Brewpub on</span>
                {Object.entries(socialNetworks).map(([network, link]) => (
                  <a href={link} key={`footer-network-${network}`} aria-label="social">
                    <img
                      src={`/static/icons/${network}.svg`}
                      height="19px"
                      alt=""
                    />
                  </a>
                ))}{" "}
              </div>
              <div className="social mt-3">
                <span>Brewery on</span>
                {/* <img
              src="/static/icons/united-states.png"
              alt=""
              style={{
                marginRight: 10,
                marginTop: 2,
              }}
            />{" "} */}
                <div className="footer-social-item">
                  <span className="country">US</span>
                  {Object.entries(socialNetworksBrewery.usa).map(
                    ([network, link]) => (
                      <a
                        href={link}
                        key={`footer-network-${network}`}
                        aria-label="social"
                      >
                        <img
                          src={`/static/icons/${network}.svg`}
                          height="19px"
                          alt=""
                        />
                      </a>
                    )
                  )}{" "}
                </div>
                {/* <img
              src="/static/icons/armenia.png"
              alt=""
              style={{
                marginRight: 10,
                marginTop: 2,
              }}
            />{" "} */}
                <div className="footer-social-item">
                  <span className="country">AM</span>
                  {Object.entries(socialNetworksBrewery.armenia).map(
                    ([network, link]) => (
                      <a
                        href={link}
                        key={`footer-network-${network}`}
                        aria-label="social"
                      >
                        <img
                          src={`/static/icons/${network}.svg`}
                          height="19px"
                          alt=""
                        />
                      </a>
                    )
                  )}{" "}
                </div>

                {/* <img
              src="/static/icons/russia.png"
              alt=""
              style={{
                marginRight: 10,
                marginTop: 2,
              }}
            />{" "} */}
                <div className="footer-social-item">
                  <span className="country">RU</span>
                  {Object.entries(socialNetworksBrewery.russia).map(
                    ([network, link]) => (
                      <a
                        href={link}
                        key={`footer-network-${network}`}
                        aria-label="social"
                      >
                        <img
                          src={`/static/icons/${network}.svg`}
                          height="19px"
                          alt=""
                        />
                      </a>
                    )
                  )}{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p>
              © {year}
              Dargett. All Rights Reserved. | developed by{" "}
              <a
                href="https://www.deepmindsystems.com"
                aria-label="deepmindsystems.com"
              >
                Deepmindsystems
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withLanguage(Footer);
